import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import backgroundImageEn1 from '../assets/home1_en.jpg';
import backgroundImageEn2 from '../assets/home2_en.jpg';
import backgroundImageJa1 from '../assets/home1_ja.jpg';
import backgroundImageJa2 from '../assets/home2_ja.jpg';
import appStoreBadge from '../assets/app_store_badge.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import googlePlayBadge from '../assets/google_play_badge.png';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        images: {
            width: '100%',
        },
        background: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            zIndex: -2,
            backgroundColor: '#ffffff', // Average color of the background image.
            backgroundPosition: 'center',
        },
        sloganCard: {
            backgroundColor: 'black',
            padding: '10px',
            margin: '0 20px',
            borderRadius: '8px',
            borderStyle: 'outset',
            borderColor: '#a6a6a6',
            borderWidth: '2px',
        },
        container: {
            minHeight: '660px',
            [theme.breakpoints.down('xs')]: {
                minHeight: '490px',
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: '860px',
            },
        },
    }),
);

function BackgroundImage1() {
    const classes = useStyles();
    const { i18n } = useTranslation();

    return (
        <div className={classes.background} style={{
            backgroundImage: 'url(' + (i18n.language === 'ja' ? backgroundImageJa1 : backgroundImageEn1) + ')'
        }} />
    );
}

function BackgroundImage2() {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setHidden(hidden => !hidden);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={classes.background} style={{
            display: hidden ? 'none' : 'block',
            backgroundImage: 'url(' + (i18n.language === 'ja' ? backgroundImageJa2 : backgroundImageEn2) + ')'
        }} />
    );
}

export default function Home() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <HelmetProvider>
            <Helmet>
                <title>{t('homeTitle')}</title>
                <meta
                    name="description"
                    content={t('metaDescription')}
                />
            </Helmet>
            <Container className={classes.container}>
                {/* Increase the network loading priority of the background image. */}
                <img style={{ display: 'none' }} src={i18n.language === 'ja' ? backgroundImageJa1 : backgroundImageEn1} alt="increase priority" />
                <img style={{ display: 'none' }} src={i18n.language === 'ja' ? backgroundImageJa2 : backgroundImageEn2} alt="increase priority" />
                <BackgroundImage1 />
                <BackgroundImage2 />
                <Box mt={6} mb={12}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={7} md={8} lg={9}>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={3}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Card className={classes.sloganCard}>
                                        <Typography variant="h5" align="center" color="secondary">
                                            {t('slogan')}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <a href="https://play.google.com/store/apps/details?id=io.kocho&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" rel="noreferrer" target="_blank">
                                        <img src={googlePlayBadge} alt="App" className={classes.images} />
                                    </a>
                                </Grid>
                                <Grid item xs={12}>
                                    <a href="https://apps.apple.com/app/id1523390825" rel="noreferrer" target="_blank">
                                        <img src={appStoreBadge} alt="App" className={classes.images} />
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </HelmetProvider>
    );
}