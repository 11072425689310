import React from 'react';
import Box from '@material-ui/core/Box';
import Markdown from 'react-markdown';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

export default function TermsConditions() {
  return (
    <Container>
      <Box mt={7} mb={12}>
        <Typography variant="h4">
          Terms & Conditions
          </Typography>
        <Markdown linkTarget="_blank">{markdown}</Markdown>
      </Box>
    </Container>
  );
}

const markdown = `
Last modified: December 26th, 2020.

These Terms govern:
- the use of Kocho, and,
- any other related Agreement or legal relationship with the Owner in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.

The User must read this document carefully.

Any other contract or agreement between the Owner and the Sellers shall always prevail over the provisions of these Terms. Therefore, in such cases, these Terms shall apply only residually and in accordance with applicable provisions in such agreements or contracts.
Although the entire contractual relationship relating to these Products is entered into solely by the Owner and Users, Users acknowledge and agree that, where Kocho has been provided to them via the Apple App Store or the Google Play Store, Apple and Google respectively may enforce these Terms as a third-party beneficiary.

Kocho is provided by:  
SCANDIACUS LLC referred in this document as the Owner  
**Address**: 2232 DELL RANGE BLVD SUITE 245 - 3382  
**Owner contact email:** contact@kocho.io  

The following documents are incorporated by reference into these Terms:

# What the User should know at a glance
-	Kocho uses automatic renewal for Product subscriptions. Information about the a) renewal period, b) termination details and c) termination notice can be found in the relevant section of these Terms.
-	The right of withdrawal only applies to European Consumers. The right of withdrawal, also commonly called the right of cancellation in the UK, is consistently referred to as “the right of withdrawal” within this document.
-	Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.
-	Usage of Kocho and the Service is age restricted, as detailed in the relevant section of this document.

# How Kocho works

The Owner does not control, monitor, moderate or inspect any Products offered by Sellers via Kocho. This means that the Owner does not bear any responsibility in connection with such Products, including but not limited to their quality, safety, accuracy, or the Sellers’ ability to provide them.
Likewise, the Owner does not control, monitor, moderate or inspect Buyers using Kocho. Therefore, the Owner does not bear any responsibility in connection with such Buyers’ activity on Kocho, including but not limited to their legal capacity or ability to complete a transaction and pay the associated costs.

By accepting the Terms, Users fully and unconditionally release and forever discharge the Owner, its officers, directors, employees and agents from any and all claims, demands and damages (actual or consequential, direct or indirect), whether now known or unknown, of every kind and nature relating to, arising out of or in any way connected with:
-	disputes between Users, or any other person or entity,
-	the Products’ use, including, without limitation, any and all claims that such use violates any of Seller’s intellectual property rights, copyrights, rights of publicity or privacy, “moral rights,” or rights of attribution and integrity, or
-	Users’ activity on Kocho, including, but not limited to, Users’ legal capacity, ability to complete a transaction, or pay the associated costs. User acknowledges and agrees that the Owner has no control over, and shall have no liability for any damages resulting from, the use or misuse by any other person or entity of any Products.
If the Owner becomes aware of any Products that allegedly may not conform to the Terms, the Owner may investigate the allegation and determine in its sole discretion whether to take action in accordance with the Terms. The Owner has no liability or responsibility to Users for performance or nonperformance of such activities. The Owner has the absolute right to remove and/or delete without notice any Products within its control that it deems objectionable. Users consent to such removal and/or deletion and waive any claim against the Owner for such removal and/or deletion. The Owner is not responsible or liable for failure to store posted content or other materials Users may transmit through Kocho. Users shall take measures to preserve copies of any data, material, content or information such User posts on Kocho. Any identity verification methods the Owner employs is strictly on a best efforts basis and shall not be relied upon by Users.

Kocho merely serves as a technical infrastructure or platform to allow Users to interact with each other. The Owner therefore is not directly involved in any such interactions between Users.
These Terms only apply to the described usage of Kocho as a platform.
Terms, conditions and any other provision applying specifically to transactions between Buyers and Sellers are specified by each Seller.

# TERMS OF USE

Unless otherwise specified, the terms of use detailed in this section apply generally when using Kocho.
Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.
By using Kocho, Users confirm to meet the following requirements:
-	There are no restrictions for Users in terms of being Consumers or Business Users;
-	Users must be older than 14;
-	Users aren’t located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist-supporting” country;
-	Users aren’t listed on any U.S. Government list of prohibited or restricted parties;

## Account registration

To use the Service, Users may register or create a User account, providing all required data or information in a complete and truthful manner.
Users may also use the Service without registering or creating a User account, however, this may cause limited availability of certain features or functions.
Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by Kocho.

By registering, Users agree to be fully responsible for all activities that occur under their username and password.
Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.

### Conditions for account registration

Registration of User accounts on Kocho is subject to the conditions outlined below. By registering, Users agree to meet such conditions.
-	Accounts registered by bots or any other automated methods are not permitted.
-	Unless otherwise specified, each User must register only one account.
-	Unless explicitly permitted, a User account may not be shared with other persons.

### Account termination

Users can terminate their account and stop using the Service at any time by doing the following:
-	By directly contacting the Owner at the contact details provided in this document.

### Account suspension and deletion

The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts which it deems inappropriate, offensive or in violation of these Terms.
The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.
The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.

## Content on Kocho

Unless where otherwise specified or clearly recognizable, all content available on Kocho is owned or provided by the Owner or its licensors.
The Owner undertakes its utmost effort to ensure that the content provided on Kocho infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.
In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.

### Rights regarding content on Kocho - All rights reserved

The Owner holds and reserves all intellectual property rights for the tools and services provided within Kocho.
Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.
In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on Kocho, nor allow any third party to do so through the User or their device, even without the User's knowledge.
Where explicitly stated on Kocho, the User may download, copy and/or share some content available through Kocho for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.
Any applicable statutory limitation or exception to copyright shall stay unaffected.

## Content provided by Users

The Owner allows Users to upload, share or provide their own content to Kocho.
By providing content to Kocho, Users confirm that they are legally allowed to do so and that they are not infringing any statutory provisions and/or third-party rights.

### Rights regarding content provided by Users

Users acknowledge and accept that by providing their own content on Kocho they grant the Owner a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of Kocho as contractually required.
To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to Kocho.
Users acknowledge, accept and confirm that all content they provide through Kocho is provided subject to the same general conditions set forth for content on Kocho.

### Liability for provided content

Users are solely liable for any content they upload, post, share, or provide through Kocho. Users acknowledge and accept that the Owner does not filter or moderate such content.
However, the Owner reserves the right to remove, delete, block or rectify such content at its own discretion and to, without prior notice, deny the uploading User access to Kocho:
-	if any complaint based on such content is received;
-	if a notice of infringement of intellectual property rights is received;
-	upon order of a public authority; or
-	where the Owner is made aware that the content, while being accessible via Kocho, may represent a risk for Users, third parties and/or the availability of the Service.
The removal, deletion, blocking or rectification of content shall not entitle Users that have provided such content or that are liable for it, to any claims for compensation, damages or reimbursement.
Users agree to hold the Owner harmless from and against any claim asserted and/or damage suffered due to content they provided to or provided through Kocho.

### Removal of content from parts of Kocho available through the App Store

If the reported content is deemed objectionable, it will be removed within 24 hours and the User who provided the content will be barred from using the Service.

## Access to external resources

Through Kocho Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.
Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.
In particular, on Kocho Users may see advertisements provided by third parties. The Owner does not control or moderate the advertisements displayed via Kocho. If Users click on any such advertisement, they will be interacting with any third party responsible for that advertisement.
The Owner is not responsible for any matters resulting from such interaction with third parties, such as anything resulting from visiting third-party websites or using third-party content.

## Acceptable use

Kocho and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.
Users are solely responsible for making sure that their use of Kocho and/or the Service violates no applicable law, regulations or third-party rights.
Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to Kocho or the Service, terminating contracts, reporting any misconduct performed through Kocho or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:
-	violate laws, regulations and/or these Terms;
-	infringe any third-party rights;
-	considerably impair the Owner’s legitimate interests;
-	offend the Owner or any third party.

## Software license

Any intellectual or industrial property rights, and any other exclusive rights on software or technical applications embedded in or related to Kocho are held by the Owner and/or its licensors.
Subject to Users’ compliance with and notwithstanding any divergent provision of these Terms, the Owner merely grants Users a revocable, non-exclusive, non-sublicensable and non-transferable license to use the software and/or any other technical means embedded in the Service within the scope and for the purposes of Kocho and the Service offered.

This license does not grant Users any rights to access, usage or disclosure of the original source code. All techniques, algorithms, and procedures contained in the software and any documentation thereto related is the Owner’s or its licensors’ sole property.
All rights and license grants to Users shall immediately terminate upon any termination or expiration of the Agreement.

Without prejudice to the above, under this license Users may download, install, use and run the software on the permitted number of devices, provided that such devices are common and up-to-date in terms of technology and market standards.
The Owner reserves the right to release updates, fixes and further developments of Kocho and/or its related software and to provide them to Users for free. Users may need to download and install such updates to continue using Kocho and/or its related software.

New releases may only be available against payment of a fee.
The User may download, install, use and run the software on one device.

## API usage terms

Users may access their data relating to Kocho via the Application Program Interface (API). Any use of the API, including use of the API through a third-party product/service that accesses Kocho, is bound by these Terms and, in addition, by the following specific terms:
-	the User expressly understands and agrees that the Owner bears no responsibility and shall not be held liable for any damages or losses resulting from the User’s use of the API or their use of any third-party products/services that access data through the API.

# TERMS AND CONDITIONS OF SALE

## Paid Products

Some of the Products provided on Kocho, as part of the Service, are provided on the basis of payment.
The fees, duration and conditions applicable to the purchase of such Products are described below and in the dedicated sections of Kocho.

## Product description

Prices, descriptions or availability of Products are outlined in the respective sections of Kocho and are subject to change without notice.
While Products on Kocho are presented with the greatest accuracy technically possible, representation on Kocho through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the purchased Product.
The characteristics of the chosen Product will be outlined during the purchasing process.

## Purchasing process

Any steps taken from choosing a Product to order submission form part of the purchasing process.

The purchasing process includes these steps:
-	Users must choose the desired Product and verify their purchase selection.
-	After having reviewed the information displayed in the purchase selection, Users may place the order by submitting it.

## Order submission

When the User submits an order, the following applies:
-	The submission of an order determines contract conclusion and therefore creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on the order page.
-	In case the purchased Product requires active input from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an obligation for the User to cooperate accordingly.
-	Upon submission of the order, Users will receive a receipt confirming that the order has been received.
All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.

## Prices

Users are informed during the purchasing process and before order submission, about any fees, taxes and costs (including, if any, delivery costs) that they will be charged.
Prices on Kocho are displayed:
-	either exclusive or inclusive of any applicable fees, taxes and costs, depending on the section the User is browsing.

## Offers and discounts

The Owner may offer discounts or provide special offers for the purchase of Products. Any such offer or discount shall always be subject to the eligibility criteria and the terms and conditions set out in the corresponding section of Kocho.
Offers and discounts are always granted at the Owner’s sole discretion.
Repeated or recurring offers or discounts create no claim/title or right that Users may enforce in the future.
Depending on the case, discounts or offers shall be valid for a limited time only or while stocks last. If an offer or discount is limited by time, the time indications refer to the time zone of the Owner, as indicated in the Owner’s location details in this document, unless otherwise specified.

## Coupons

Offers or discounts can be based on Coupons.
If breach of the conditions applicable to Coupons occurs, the Owner can legitimately refuse to fulfill its contractual obligations and expressly reserves the right to take appropriate legal action to protect its rights and interests.
Notwithstanding the provisions below, any additional or diverging rules applicable to using the Coupon displayed in the corresponding information page or on the Coupon itself shall always prevail.

Unless otherwise stated, these rules apply to the use of Coupons:
-	Each Coupon is only valid when used in the manner and within the timeframe specified on the website and/or the Coupon;
-	A Coupon may only be applied, in its entirety, at the actual time of purchase – partial use is not permitted;
-	Unless otherwise stated, single-use Coupons may only be used once per purchase and therefore may only be applied a single time even in cases involving installment-based purchases;
-	A Coupon cannot be applied cumulatively;
-	The Coupon must be redeemed exclusively within the time specified in the offer. After this period, the Coupon will automatically expire, precluding any possibility for the User to claim the relevant rights, including cash-out;
-	The User is not entitled to any credit/refund/compensation if there is a difference between the value of the Coupon and the redeemed value;
-	The Coupon is intended solely for non–commercial use. Any reproduction, counterfeiting and commercial trade of the Coupon is strictly forbidden, along with any illegal activity related to the purchase and/or use of the Coupon.

## Methods of payment

Information related to accepted payment methods are made available during the purchasing process.
Some payment methods may only be available subject to additional conditions or fees. In such cases related information can be found in the dedicated section of Kocho.
All payments are independently processed through third-party services. Therefore, Kocho does not collect any payment information – such as credit card details – but only receives a notification once the payment has been successfully completed.
If payment through the available methods fail or is refused by the payment service provider, the Owner shall be under no obligation to fulfil the purchase order. Any possible costs or fees resulting from the failed or refused payment shall be borne by the User.

## Virtual Currency for exclusive use inside Kocho

On Kocho certain payments may be made using a Virtual Currency. Unless otherwise stated, such Virtual Currency is not tradable, exchangeable or redeemable against any traditional currency, any other open digital currency, goods or any other values.
By purchasing Virtual Currency, Users acknowledge and understand that it may only be used within Kocho for the purposes expressly authorized by the Owner within the framework of its Services. Users also acknowledge and agree that they may not transfer, purchase, sell, or exchange such Virtual Currency outside of the Service.

Accordingly, Users may not sublicense, trade, sell or attempt to sell Virtual Currency for money, or exchange Virtual Currency for value of any kind outside of the dedicated offering provided by the Owner through Kocho. Any such prohibited use or transaction shall be considered null and void and could result in legal action being taken against the User.
In case of contract or account termination for any cause attributable to the User, any and all unused Virtual Currency shall be forfeited and no refund shall be granted.

## Purchase via app store

Kocho or specific Products available for sale on Kocho must be purchased via a third-party app store. To access such purchases, Users must follow the instructions provided on the relevant online store (such as "Apple App Store" or "Google Play"), which may vary depending on the particular device in use.
Unless otherwise specified, purchases done via third-party online stores are also subject to such third-parties’ terms and conditions, which, in case of any inconsistency or conflict, shall always prevail upon these Terms.
Users purchasing through such third-party online stores must therefore read such terms and conditions of sale carefully and accept them.

## Retention of usage rights

Users do not acquire any rights to use the purchased Product until the total purchase price is received by the Owner.

## Delivery

### Performance of services

The purchased service shall be performed or made available within the timeframe specified on Kocho or as communicated before the order submission.

## Contract duration

### Trial period

Users might have the option to test Kocho or selected Products during a limited and non-renewable trial period, at no cost. Some features or functions of Kocho may not be available to Users during the trial period.
Further conditions applicable to the trial period, including its duration, will be specified on Kocho.
The trial period shall automatically convert into the equivalent paid Product, unless the User cancels the purchase before the trial period expires.

### Subscriptions

Subscriptions allow Users to receive a Product continuously or regularly over a determined period of time.
Paid subscriptions begin on the day the payment is received by the Owner.
In order to maintain subscriptions, Users must pay the required recurring fee in a timely manner. Failure to do so may cause service interruptions.

### Fixed-term subscriptions

Paid fixed-term subscriptions start on the day the payment is received by the Owner and last for the subscription period chosen by the User or otherwise specified during the purchasing process.
Once the subscription period expires, the Product shall no longer be accessible, unless the User renews the subscription by paying the relevant fee.
Fixed-term subscriptions may not be terminated prematurely and shall run out upon expiration of the subscription term.

### Subscriptions handled via Apple ID

Users may subscribe to a Product using the Apple ID associated with their Apple App Store account by using the relevant process on Kocho. When doing so, Users acknowledge and accept that
-	any payment due shall be charged to their Apple ID account;
-	subscriptions are automatically renewed for the same duration unless the User cancels at least 24 hours before the current period expires;
-	any and all fees or payments due for renewal will be charged within 24-hours before the end of the current period;
-	subscriptions can be managed or cancelled in the Users’ Apple App Store account settings.
The above shall prevail upon any conflicting or diverging provision of these Terms.

### Automatic renewal

Subscriptions are automatically renewed through the payment method that the User chose during purchase, unless the User cancels the subscription within the deadlines for termination specified in the relevant section of these Terms and/or Kocho.
The renewed subscription will last for a period equal to the original term.
The User shall receive a reminder of the upcoming renewal with reasonable advance, outlining the procedure to be followed in order to cancel the automatic renewal.

### Termination

Recurring subscriptions may be terminated at any time by sending a clear and unambiguous termination notice to the Owner using the contact details provided in this document, or — if applicable — by using the corresponding controls inside Kocho.

### Termination notice

If the notice of termination is received by the Owner before the subscription renews, the termination shall take effect as soon as the current period is completed.

# User rights

## Right of withdrawal

Unless exceptions apply, the User may be eligible to withdraw from the contract within the period specified below (generally 14 days), for any reason and without justification. Users can learn more about the withdrawal conditions within this section.

### Who the right of withdrawal applies to

Unless any applicable exception is mentioned below, Users who are European Consumers are granted a statutory cancellation right under EU rules, to withdraw from contracts entered into online (distance contracts) within the specified period applicable to their case, for any reason and without justification.
Users that do not fit this qualification, cannot benefit from the rights described in this section.

### Exercising the right of withdrawal

To exercise their right of withdrawal, Users must send to the Owner an unequivocal statement of their intention to withdraw from the contract.
To this end, Users may use the model withdrawal form available from within the “definitions” section of this document. Users are, however, free to express their intention to withdraw from the contract by making an unequivocal statement in any other suitable way. In order to meet the deadline within which they can exercise such right, Users must send the withdrawal notice before the withdrawal period expires.
When does the withdrawal period expire?
-	Regarding the purchase of a service, the withdrawal period expires 14 days after the day that the contract is entered into, unless the User has waived the withdrawal right.

### Effects of withdrawal

Users who correctly withdraw from a contract will be reimbursed by the Owner for all payments made to the Owner, including, if any, those covering the costs of delivery.
However, any additional costs resulting from the choice of a particular delivery method other than the least expensive type of standard delivery offered by the Owner, will not be reimbursed.
Such reimbursement shall be made without undue delay and, in any event, no later than 14 days from the day on which the Owner is informed of the User’s decision to withdraw from the contract. Unless otherwise agreed with the User, reimbursements will be made using the same means of payment as used to process the initial transaction. In any event, the User shall not incur any costs or fees as a result of such reimbursement.

#### …on the purchase of services

Where a User exercises the right of withdrawal after having requested that the service be performed before the withdrawal period expires, the User shall pay to the Owner an amount which is in proportion to the part of service provided.
Such payment shall be calculated based on the economic value of the service and be proportional to the part of service provided until the time the User withdraws compared to the full coverage of the contract.

# Liability and indemnification

## EU Users

### Limitation of liability for User activities on Kocho

Users acknowledge and accept that the Owner merely provides Users with the technical infrastructure and features incorporated in Kocho.
The Owner does not intermediate, moderate, promote or intervene in interactions, agreements or transactions between Users and therefore bears no liability for any such interactions among Users, including the performance of any Users' obligations.
Users, in particular, acknowledge and accept that the Owner is not involved in sales and purchases by Users qualifying respectively as Sellers or Buyers over Kocho.
This means that Sellers and Buyers are solely liable for respectively offering and purchasing through Kocho and for the obligations resulting thereof.
In particular, the Owner shall bear no liability for:
-	any pre-contractual statement, claim or description of the Products offered through/via Kocho;
-	the existence of any applicable license, authorization, qualification or other official permit allowing Sellers to offer specific goods or services, as may be required by applicable law;
-	the Buyers' eligibility (e.g. in terms of age, solvency etc.) for purchase according to applicable law;
-	any obligation stipulated by Users over Kocho, including but not limited to product guarantees and product safety;
-	any claim based on partial, incorrect or failed performance of binding agreements entered into on Kocho.

## Australian Users

### Limitation of liability

Nothing in these Terms excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act 2010 (Cth) or any similar State and Territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms of Use, is limited, at the Owner’s sole discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.

## US Users

###  Disclaimer of Warranties

Kocho is provided strictly on an “as is” and “as available” basis. Use of the Service is at Users’ own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by user from owner or through the Service will create any warranty not expressly stated herein.

Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users’ requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at users own risk and users shall be solely responsible for any damage to Users’ computer system or mobile device or loss of data that results from such download or Users’ use of the Service.

The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.
The Service may become inaccessible or it may not function properly with Users’ web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.
Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.

### Limitations of liability

To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for
-	any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and
-	any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;
-	any errors, mistakes, or inaccuracies of content;
-	personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;
-	any unauthorized access to or use of the Owner’s secure servers and/or any and all personal information stored therein;
-	any interruption or cessation of transmission to or from the Service;
-	any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;
-	any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or
-	the defamatory, offensive, or illegal conduct of any User or third party. In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.
This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if company has been advised of the possibility of such damage.
Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User. The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.

### Indemnification

The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from
-	User’s use of and access to the Service, including any data or content transmitted or received by User;
-	User’s violation of these terms, including, but not limited to, User’s breach of any of the representations and warranties set forth in these terms;
-	User’s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;
-	User’s violation of any statutory law, rule, or regulation;
-	any content that is submitted from User’s account, including third party access with User’s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;
-	User’s willful misconduct; or
-	statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.

# Common provisions

## No Waiver

The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.

## Service interruption

To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.
Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.
Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” (eg. labor actions, infrastructural breakdowns or blackouts etc).

## Service reselling

Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of Kocho and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling program.

## Privacy policy

To learn more about the use of their Personal Data, Users may refer to the privacy policy of Kocho.

## Intellectual property rights

Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to Kocho are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.
All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with Kocho are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.

## Changes to these Terms

The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.
Such changes will only affect the relationship with the User for the future.
The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement.
The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.
If required by applicable law, the Owner will specify the date by which the modified Terms will enter into force.

## Assignment of contract

The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly.
Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.

## Contacts

All communications relating to the use of Kocho must be sent using the contact information stated in this document.

## Severability

Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.

### EU Users

Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.
In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.

Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.

### US Users

Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent. These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter. These Terms will be enforced to the fullest extent permitted by law.

## Governing law

These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.

### Exception for European Consumers

However, regardless of the above, if the User qualifies as a European Consumer and has their habitual residence in a country where the law provides for a higher consumer protection standard, such higher standards shall prevail.

## Venue of jurisdiction

The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.

### Exception for European Consumers

The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in Switzerland, Norway or Iceland.

# Dispute resolution

## Amicable dispute resolution

Users may bring any disputes to the Owner who will try to resolve them amicably.
While Users' right to take legal action shall always remain unaffected, in the event of any controversy regarding the use of Kocho or the Service, Users are kindly asked to contact the Owner at the contact details provided in this document.
The User may submit the complaint including a brief description and if applicable, the details of the related order, purchase, or account, to the Owner’s email address specified in this document.
The Owner will process the complaint without undue delay and within 21 days of receiving it.

## Amicable resolution of disputes between Users

Users may bring disputes with other Users resulting from their interaction via Kocho to the Owner, who will then try to mediate the conflict in order to achieve an amicable solution. While Users' right to take legal action shall always remain unaffected, if any such controversy between Users should arise in connection with using Kocho or the Service, Users are kindly asked to contact the Owner at the contact details provided in this document.

## Online dispute resolution for Consumers

The European Commission has established an online platform for alternative dispute resolutions that facilitates an out-of-court method for solving any dispute related to and stemming from online sale and service contracts.
As a result, any European Consumer can use such platform for resolving any dispute stemming from contracts which have been entered into online. The platform is available [at the following link](http://ec.europa.eu/consumers/odr/).

### Germany: Dispute resolution procedure with Consumer conciliation boards

The Owner does not participate in alternative dispute resolution procedures for Consumers under the German Verbraucherstreitbeilegungsgesetz.

### France: Mediation
Within one year of submitting a written complaint to the Owner regarding any dispute stemming from these Terms, Consumers have the right to initiate a mediation procedure before
-	any mediation body approved by the French Government. The relevant list is available [at the following link](https://www.economie.gouv.fr/mediation-conso/mediateurs-references).
`