import AppBar from './components/AppBar';
import AppFooter from './components/AppFooter';
import ScrollToTop from './components/ScrollToTop';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';

import Home from './pages/Home';
import Docs from './pages/Docs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import ContactUs from './pages/ContactUs';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0277bd',
    },
    secondary: {
      main: '#ffffff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppBar />
        <ScrollToTop />
        <Switch>
          <Route path="/docs">
            <Docs />
            <AppFooter />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
            <AppFooter />
          </Route>
          <Route path="/terms-conditions">
            <TermsConditions />
            <AppFooter />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
            <AppFooter />
          </Route>
          <Route path={["/", "/es/", "/pt/", "/ja/"]}>
            <Home />
            <AppFooter credits={true} />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
