import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iframe: {
            overflow: 'hidden',
            border: 0,
            width: '100%',
            height: '1000px',
        },
    }),
);

export default function ContactUs() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <iframe
                title="contact-form"
                src="https://docs.google.com/forms/d/e/1FAIpQLSfzWjLQ5EPkBP907IzKXcjNuNgi0uFGcU6IES-kDFVF0A5qTg/viewform?embedded=true"
                className={classes.iframe}>
            </iframe>
        </React.Fragment>
    );
}