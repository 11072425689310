import '../css/Docs.css';
import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

export default function Docs() {
    return (
        <Container>
            <Box mt={7} mb={12}>
                <Typography variant="h4">
                    Docs
                    </Typography>
                <br></br>
                <Typography variant="body1">
                    We are updating this page, in the meantime you can watch our video tutorial here:
                    </Typography>
                <Box mt={4} mb={12}>
                    <div className='embed-container'>
                        <iframe
                            title="video-tutorial"
                            src='https://www.youtube.com/embed/289Xn-QaXGk'
                            frameBorder='0' allowFullScreen></iframe>
                    </div>
                </Box>
            </Box>
        </Container>
    );
}