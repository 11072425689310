import React from 'react';
import '../css/AppFooter.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import redditIcon from '../assets/reddit.png';
import twitterIcon from '../assets/twitter.png';
import youTubeIcon from '../assets/youtube.png';

function Copyright() {
  return (
    <React.Fragment>
      {'© Kocho '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

// const LANGUAGES = [
//   {
//     code: 'en',
//     name: 'English',
//   },
//   {
//     code: 'es',
//     name: 'Español',
//   },
//   {
//     code: 'pt',
//     name: 'Português',
//   },
//   {
//     code: 'ja',
//     name: '日本語',
//   },
// ];

export default function AppFooter(props) {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item xs={12} className={classes.icons}>
                <Link href="https://www.reddit.com/r/KochoVN/" target="_blank">
                  <img src={redditIcon} alt="Reddit" className={classes.icon} />
                </Link>
                <Link href="https://www.youtube.com/channel/UCPMmKacgP9xOPmVr5MR5CLQ" target="_blank">
                  <img src={youTubeIcon} alt="YouTube" className={classes.icon} />
                </Link>
                <Link href="https://twitter.com/kocho_vn" target="_blank">
                  <img src={twitterIcon} alt="Twitter" className={classes.icon} />
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Legal
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link component={RouterLink} to="/terms-conditions">
                  Terms & Conditions
                </Link>
              </li>
              <li className={classes.listItem}>
                <Link component={RouterLink} to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
              <li className={classes.listItem}>
                <Link component={RouterLink} to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </Grid>
          {/* <Grid item xs={6} sm={8} md={4}>
            <Typography variant="h6" marked="left" gutterBottom>
              Language
            </Typography>
            <TextField
              select
              SelectProps={{
                native: true,
              }}
              className={classes.language}
            >
              {LANGUAGES.map((language) => (
                <option value={language.code} key={language.code}>
                  {language.name}
                </option>
              ))}
            </TextField>
          </Grid> */}
          {props.credits ?
            <Grid item>
              <Typography variant="caption">
                {'Background image made by '}
                <Link href="http://creativefreaks.net/"
                  target="_blank">
                  Creative Freaks
              </Link>
              </Typography>
            </Grid>
            : ''}
        </Grid>
      </Container>
    </Typography>
  );
}
