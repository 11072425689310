import React from 'react';
import Link from '@material-ui/core/Link';
import MuiAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/appbar_logo.svg';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const logoHeight = 25;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        logo: {
            height: logoHeight,
        },
        offset: theme.mixins.toolbar,
    }),
);

export default function AppBar() {
    const classes = useStyles();

    return (
        <div className={classes.grow}>
            <MuiAppBar position="fixed">
                <Toolbar>
                    <Link component={RouterLink} to='/' className={classes.logo}>
                        <Logo fill='white' height={logoHeight + 'px'} />
                    </Link>
                    <div className={classes.grow} />
                        <Link component={RouterLink} to="/docs">
                            <Button color="secondary">
                                Docs
                            </Button>
                        </Link>
                </Toolbar>
            </MuiAppBar>
            <div className={classes.offset} />
        </div>
    );
}
